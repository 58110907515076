<template>
    <unidade-form operacao="ATUALIZAR" @salvar="atualizar($event)" :cancelar="cancelar" :erros="erros" :unidade="unidade"></unidade-form>
</template>

<script>
import UnidadesServices from './services';
import UnidadeForm from './UnidadeForm';

export default {
    components: {
        UnidadeForm,
    },

    data() {
        return {
            erros: [],
            unidade: null,
            atualizado: false,
        };
    },

    methods: {
        cancelar() {
            if (this.atualizado) {
                this.$store.dispatch('setAtualizar', true);
            }
            this.$router.push({
                name: 'Expressos_Unidades_Detalhar',
                params: {
                    id: this.$route.params.id,
                },
            });
        },

        obterUnidade() {
            this.$store.dispatch('addRequest');
            UnidadesServices.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.unidade = response.data;
                } else {
                    this.unidade = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar(unidade) {
            this.$store.dispatch('addRequest');
            unidade.unidadeParametrosId = this.$route.params.id;
            UnidadesServices.atualizar(unidade).then((response) => {
                if (response?.success) {
                    this.atualizado = true;
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Unidade de Atendimento',
                        detail: 'Unidade de atendimento atualizada com sucesso',
                        life: 3000,
                    });
                    this.cancelar();
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    mounted() {
        this.obterUnidade();
    },
};
</script>
