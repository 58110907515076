import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_EXPRESSOS_PORT}${process.env.VUE_APP_API_PATH}`;

export default {
    obterTodas() {
        return axiosJwt.get(`${api}/expressos/unidades`);
    },

    obterOpcoes() {
        return axiosJwt.get(`${api}/expressos/unidades/opcoes`);
    },

    obterParceiroPorCpf(cpf) {
        return axiosJwt.get(`${api}/expressos/unidades/responsavel/${cpf}`);
    },

    obterPorId(id) {
        return axiosJwt.get(`${api}/expressos/unidades/${id}`);
    },

    inserir(unidade) {
        return axiosJwt.post(`${api}/expressos/unidades/inserir`, unidade);
    },

    atualizar(unidade) {
        return axiosJwt.post(`${api}/expressos/unidades/atualizar`, unidade);
    },
};
