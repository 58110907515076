<template>
    <painel titulo="Expressos - Unidade de Atendimento" icone="building" :refreshFunction="atualizar">
        <erros-box :erros="erros"></erros-box>
        <div class="formgrid grid p-fluid">
            <div class="field col-12 lg:col-6">
                <label class="required">Unidade de Atendimento</label>
                <Dropdown
                    v-model="codUnidadeAtendimento"
                    :options="unidades"
                    optionLabel="descricao"
                    optionValue="codigo"
                    :filter="true"
                    placeholder="Selecione a unidade de atendimento"
                    :showClear="true"
                    :filterFields="['codigo', 'descricao']"
                    v-if="unidades"
                >
                </Dropdown>
            </div>
            <div class="field col-12 lg:col-6">
                <label class="required">Unidade Organizacional</label>
                <Dropdown
                    v-model="codigoUnidadeOrganizacional"
                    :options="unidadesOrganizacionais"
                    optionLabel="descricao"
                    optionValue="codigoUnidadeOrganizacional"
                    :filter="true"
                    placeholder="Selecione a unidade organizacional"
                    :showClear="true"
                    :filterFields="['codigoUnidadeOrganizacional', 'descricao']"
                    v-if="unidadesOrganizacionais"
                >
                </Dropdown>
            </div>
            <div class="field col-12 lg:col-6">
                <label class="required">Projeto</label>
                <Dropdown
                    v-model="codProjeto"
                    :options="projetos"
                    optionLabel="nomePratif"
                    optionValue="codPratif"
                    :filter="true"
                    placeholder="Selecione o projeto"
                    :showClear="true"
                    :filterFields="['nomePratif']"
                    v-if="projetos"
                ></Dropdown>
            </div>
            <div class="field col-12 lg:col-6">
                <label class="required">Ação</label>
                <Dropdown
                    v-model="codAcao"
                    :options="acoesProjeto"
                    optionLabel="nomeAcao"
                    optionValue="codAcao"
                    :filter="true"
                    placeholder="Selecione a ação"
                    :showClear="true"
                    :filterFields="['nomeAcao']"
                    v-if="acoesProjeto"
                ></Dropdown>
            </div>
            <div class="field col-12 md:col-3 lg:col-2">
                <label class="required">CPF Responsável</label>
                <div class="p-inputgroup">
                    <InputMask
                        v-model="cpfResponsavel"
                        mask="999.999.999-99"
                        :unmask="true"
                        :disabled="cpfPesquisado"
                        :class="{ 'p-invalid': v$.cpfResponsavel.$error }"
                        @keypress="v$.cpfResponsavel.$touch()"
                        @keypress.enter="pesquisarCpf()"
                    />
                    <Button v-if="!cpfPesquisado" icon="pi pi-search" @click="pesquisarCpf()" title="Clique para pesquisar o CPF" />
                    <Button v-if="cpfPesquisado" icon="pi pi-undo" @click="informarNovoCpf()" title="Clique para informar um novo CPF" />
                </div>
                <small class="p-error" v-if="v$.cpfResponsavel.$error">O CPF é obrigatório</small>
            </div>
            <div class="field col-12 md:col-9 lg:col-10">
                <label>Responsável</label>
                <InputText type="text" v-model="responsavel" :disabled="true" />
            </div>
            <div class="field col-12 md:col-3">
                <label class="required">Instrumento</label>
                <Dropdown
                    v-model="codInstrumento"
                    :options="instrumentos"
                    optionLabel="descCategoria"
                    optionValue="codCategoria"
                    :filter="true"
                    placeholder="Selecione o instrumento"
                    :showClear="true"
                    :filterFields="['descCategoria']"
                    v-if="instrumentos"
                ></Dropdown>
            </div>
            <div class="field col-12 md:col-3">
                <label class="required">Tipo da Realização</label>
                <InputText type="text" v-model="tipoRealizacao" @input="v$.tipoRealizacao.$touch()" />
                <small class="p-error" v-if="v$.tipoRealizacao.$error">O tipo da realização é obrigatório</small>
            </div>
            <div class="field col-12 md:col-3">
                <label class="required">Nome da Realização</label>
                <InputText type="text" v-model="nomeRealizacao" @input="v$.nomeRealizacao.$touch()" />
                <small class="p-error" v-if="v$.nomeRealizacao.$error">O nome da realização é obrigatório</small>
            </div>
            <div class="field col-12 md:col-3">
                <label class="required">Carga Horária</label>
                <InputNumber v-model="cargaHoraria" suffix=" minutos" @input="v$.cargaHoraria.$touch()" />
                <small class="p-error" v-if="v$.cargaHoraria.$error">A carga horária é obrigatória</small>
            </div>
            <div class="field col-12">
                <label>Lista de emails para divulgação das integrações</label>
                <Chips v-model="listaEmailsIntegracao" :allowDuplicate="false" />
            </div>
        </div>
        <slot name="botoes"></slot>
        <Button label="Cancelar" icon="pi pi-times-circle" class="p-button-secondary" @click="cancelar" />
        <Button label="Salvar" icon="pi pi-check-circle" class="ml-2 p-button-primary" @click="confirmarSalvar()" :disabled="v$.$invalid" />
    </painel>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import UnidadesServices from './services';

export default {
    setup() {
        return { v$: useVuelidate() };
    },

    props: {
        operacao: {
            type: String,
        },

        unidade: {
            type: Object,
        },

        erros: {
            type: Array,
        },

        cancelar: {
            type: Function,
        },
    },

    emits: ['salvar', 'atualizar', 'pesquisarCpf'],

    data() {
        return {
            codUnidadeAtendimento: null,
            unidadeAtendimento: null,
            codInstrumento: null,
            tipoRealizacao: null,
            nomeRealizacao: null,
            cargaHoraria: null,
            codigoUnidadeOrganizacional: null,
            codAcao: null,
            codProjeto: null,
            cpfResponsavel: null,
            codParceiro: null,
            nomeResponsavel: null,
            unidadesOrganizacionais: [],
            projetos: [],
            acoes: [],
            unidades: [],
            instrumentos: [],
            cpfPesquisado: false,
            acaoInicial: true,
            listaEmailsIntegracao: null,
        };
    },

    validations() {
        return {
            codUnidadeAtendimento: { required },
            codInstrumento: { required },
            tipoRealizacao: { required },
            nomeRealizacao: { required },
            cargaHoraria: { required },
            codigoUnidadeOrganizacional: { required },
            codAcao: { required },
            codProjeto: { required },
            cpfResponsavel: { required },
        };
    },

    methods: {
        obterOpcoes() {
            this.$store.dispatch('addRequest');
            UnidadesServices.obterOpcoes().then((response) => {
                if (response?.success) {
                    this.unidadesOrganizacionais = response.data.unidadesOrganizacionais;
                    this.projetos = response.data.projetos;
                    this.acoes = response.data.acoes;
                    this.instrumentos = response.data.instrumentos;
                    this.unidades = response.data.unidades;
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Unidade de Atendimento',
                        detail: 'Falha ao obter opções de seleção',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },

        preencher() {
            this.codUnidadeAtendimento = this.unidade?.codUnidadeAtendimento;
            this.unidadeAtendimento = this.unidade?.unidadeAtendimento;
            this.codInstrumento = this.unidade?.codInstrumento;
            this.instrumento = this.unidade?.instrumento;
            this.tipoRealizacao = this.unidade?.tipoRealizacao;
            this.nomeRealizacao = this.unidade?.nomeRealizacao;
            this.cargaHoraria = this.unidade?.cargaHoraria;
            this.codigoUnidadeOrganizacional = this.unidade?.codigoUnidadeOrganizacional;
            this.unidadeOrganizacional = this.unidade?.unidadeOrganizacional;
            this.codAcao = this.unidade?.codAcao;
            this.codAcaoSeq = this.unidade?.codAcaoSeq;
            this.acao = this.unidade?.acao;
            this.codProjeto = this.unidade?.codProjeto;
            this.projeto = this.unidade?.projeto;
            this.cpfResponsavel = this.unidade?.cpfResponsavel;
            this.codParceiro = this.unidade?.codParceiro;
            this.responsavel = this.unidade?.responsavel;
            this.listaEmailsIntegracao = this.unidade?.listaEmailsIntegracao;
            this.cpfPesquisado = true;
            this.v$.$touch();
        },

        confirmarSalvar() {
            this.$confirm.require({
                message: `Tem certeza que deseja ${this.operacao.toLowerCase()} a unidade?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            let instrumento = this.instrumentos.filter((item) => {
                return item.codCategoria === this.codInstrumento;
            })[0];

            let projeto = this.projetos.filter((item) => {
                return item.codPratif === this.codProjeto;
            })[0];

            let acao = this.acoes.filter((item) => {
                return item.codAcao === this.codAcao;
            })[0];

            let unidadeOrganizacional = this.unidadesOrganizacionais.filter((item) => {
                return item.codigoUnidadeOrganizacional === this.codigoUnidadeOrganizacional;
            })[0];

            let unidade = this.unidades.filter((item) => {
                return item.codigo === this.codUnidadeAtendimento;
            })[0];

            let unidadeDto = {
                codUnidadeAtendimento: this.codUnidadeAtendimento,
                unidadeAtendimento: unidade.descricao,
                codInstrumento: this.codInstrumento,
                instrumento: instrumento.descCategoria,
                tipoRealizacao: this.tipoRealizacao,
                nomeRealizacao: this.nomeRealizacao,
                cargaHoraria: this.cargaHoraria,
                codigoUnidadeOrganizacional: this.codigoUnidadeOrganizacional,
                unidadeOrganizacional: unidadeOrganizacional.descricao,
                codAcao: this.codAcao,
                codAcaoSeq: acao.codAcaoSeq,
                acao: acao.nomeAcao,
                codProjeto: this.codProjeto,
                projeto: projeto.nomePratif,
                cpfResponsavel: this.cpfResponsavel,
                codParceiro: this.codParceiro,
                responsavel: this.responsavel,
                listaEmailsIntegracao: this.listaEmailsIntegracao,
            };
            this.$emit('salvar', unidadeDto);
        },

        atualizar() {
            this.$emit('atualizar');
        },

        pesquisarCpf() {
            this.$store.dispatch('addRequest');
            UnidadesServices.obterParceiroPorCpf(this.cpfResponsavel).then((response) => {
                if (response?.success) {
                    this.codParceiro = response.data.codParceiro;
                    this.responsavel = response.data.nomeRazaoSocial;
                    this.cpfPesquisado = true;
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Unidade de Atendimento',
                        detail: 'Falha ao obter o parceiro',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },

        informarNovoCpf() {
            this.cpfResponsavel = null;
            this.codParceiro = null;
            this.responsavel = null;
            this.cpfPesquisado = false;
        },
    },

    mounted() {
        this.obterOpcoes();
    },

    computed: {
        acoesProjeto() {
            return this.acoes.filter((item) => {
                return item.codPratif === this.codProjeto;
            });
        },
    },

    watch: {
        unidade() {
            this.preencher();
        },

        codProjeto() {
            if (this.operacao == 'INSERIR' || (this.operacao == 'ATUALIZAR' && !this.acaoInicial)) {
                this.codAcao = null;
            }
            if (this.operacao == 'ATUALIZAR' && this.acaoInicial) {
                this.acaoInicial = false;
            }
        },
    },
};
</script>
